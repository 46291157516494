<template>
  <div>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        width="800"
        persistent
    >
      <v-card>
        <v-card-title class="bg-prosk-secondary white--text">
          <span v-if="(myCalendar && selectedEvent.client_id === currentUser.id && selectedEvent.prosker_id !== currentUser.id)">
            {{ this.$t('eventWith', {clientName: `${selectedEvent.prosker.first_name} ${selectedEvent.prosker.last_name}`}) }}
          </span>
          <span v-if="(myCalendar && selectedEvent.prosker_id === currentUser.id && selectedEvent.client_id !== currentUser.id)">
            {{ this.$t('eventWith', {clientName: `${selectedEvent.client.first_name} ${selectedEvent.client.last_name}`}) }}
          </span>
          <span v-if="(myCalendar && selectedEvent.prosker_id === currentUser.id && selectedEvent.client_id === currentUser.id)">
            {{ this.$t('myEvent') }}
          </span>
          <v-btn fab small absolute right @click="closeEventDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="border-bottom border-primary mb-6">
            <v-col class="text-center pt-5">
              <h4 class="font-size-18">{{ (myCalendar && selectedEvent.prosker_id === currentUser.id) ? $t('manageEvent') : (selectedEvent.is_new) ? $t('calendarEventTitle') : $t('myEvent') }}
              </h4>
              <p class="m-0" v-if="!myCalendar">
                <span v-if="selectedEvent.approved === '0'">{{ $t('calendarEventDescription') }}</span>
                <span v-if="selectedEvent.approved === '-1'">{{ $t('calendarEventDescriptionRejected') }}</span>
                <span v-if="selectedEvent.approved === '1'">{{ $t('calendarEventDescriptionApproved') }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                  class="p-0"
                  :label="$t('date')"
                  type="date"
                  :min="today"
                  v-model="selectedEvent.event_date"
                  @input="changedDate"
                  :disabled="!selectedEvent.is_new"
              ></v-text-field>
              {{ $t('beginning') }}
              <vue-timepicker
                  hide-disabled-items
                  :minute-interval="60"
                  :disabled="!selectedEvent.is_new"
                  v-model="selectedEvent.start_time"
              ></vue-timepicker>
              <v-text-field
                  type="hidden"
                  :hide-details="!eventTimeErrors.length"
                  :error-count="eventTimeErrors.length"
                  :error-messages="eventTimeErrors"
              ></v-text-field>
              <v-alert
                  v-if="selectedEvent.is_new"
                  border="top"
                  color="#59D79D"
                  colored-border
                  type="info"
                  elevation="2"
                  class="mt-5"
              >
                <p class="mt-0 mb-2 font-weight-light text-caption">{{ $t('proskerAvailabilityEventLength') }}</p>
                <p class="mt-0 mb-2 font-weight-light text-caption">{{ $t('proskerAvailabilityMessage') }}</p>
                <p class="my-0 font-weight-light text-caption" v-for="(timeRange, index) in selectedEvent.available_times" :key="`time${index}`">
                  {{ timeRange.start_time }} - {{ timeRange.end_time }}
                </p>
              </v-alert>
            </v-col>
            <v-col md="6" cols="12">
              <v-textarea
                  outlined
                  :label="$t('comments')"
                  v-model="selectedEvent.comments"
                  :disabled="!selectedEvent.is_new"
              ></v-textarea>
              <v-radio-group
                  v-if="myCalendar && selectedEvent.prosker_id === currentUser.id"
                  v-model="selectedEvent.approved"
                  row
              >
                <v-radio
                    :label="$t('eventApprove')"
                    value="1"
                    color="teal"
                ></v-radio>
                <v-radio
                    :label="$t('eventReject')"
                    value="-1"
                    color="teal"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <cancel-button :cancel="closeEventDialog" :message="$t('cancel')"/>
            <save-button :save="saveEvent" :loading="saving" :message="myCalendar ? $t('accept') : $t('save')"
                         v-if="selectedEvent.is_new || myCalendar"/>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import VueTimepicker from 'vue2-timepicker';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
  name: 'UserCalendarEvent',
  mixins: [errorDialog],
  props: ['selectedDate', 'selectedEvent', 'dialog', 'profileUserId', 'myCalendar'],
  components: { CancelButton, SaveButton, VueTimepicker },
  data () {
    return {
      loading: true,
      skeletonAttrs: {
        class: '',
        boilerplate: true,
        elevation: 2
      },
      eventTimeErrors: [],
      today: moment().toString(),
      saving: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  methods: {
    closeEventDialog (reloadEvents) {
      this.eventTimeErrors = [];
      this.$emit('close-event-dialog', { reloadEvents: reloadEvents });
    },
    validEvent (event) {
      return (event.start_time && this.eventInValidTimeRange(event));
    },
    eventInValidTimeRange (event) {
      let inAValidRange = 0;
      event.available_times.forEach(item => {
        if (event.start_time >= item.start_time && moment(`2024-01-01T${event.start_time}`).add(1, 'hours').format('hh:mm') <= item.end_time) {
          inAValidRange++;
        }
      });
      return inAValidRange > 0;
    },
    changedDate (value) {
      this.eventTimeErrors = [];
      this.$emit('changed-event-date', { date: value });
    },
    createEvent (data) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/events`;
      axios
          .post(url, data)
          .then((response) => {
            this.saving = false;
            this.closeEventDialog(true);
          })
          .catch((error) => {
            this.saving = false;
            this.showError(error);
          });
    },
    updateEvent (data) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/events/${this.selectedEvent.id}`;
      axios
          .patch(url, data)
          .then((response) => {
            this.saving = false;
            this.closeEventDialog(true);
            const eventData = response.data.data;
            if (+eventData.approved === 1) {
              this.$router.push({ name: 'social.services', params: { query: 'mis-servicios' } });
            }
          })
          .catch((error) => {
            this.saving = false;
            this.showError(error);
          });
    },
    saveEvent () {
      this.eventTimeErrors = [];
      if (!this.validEvent(this.selectedEvent)) {
        this.eventTimeErrors.push(this.$t('eventTimeError'));
      } else {
        this.saving = true;
        const data = {
          prosker_id: this.selectedEvent.is_new ? this.profileUserId : this.selectedEvent.prosker_id,
          client_id: this.selectedEvent.is_new ? this.currentUser.id : this.selectedEvent.client_id,
          event_date: this.selectedEvent.event_date,
          comments: this.selectedEvent.comments,
          approved: this.selectedEvent.is_new ? 0 : this.selectedEvent.approved,
          start_time: moment(`2024-01-01T${this.selectedEvent.start_time}`).format('hh:mm:ss'),
          end_time: moment(`2024-01-01T${this.selectedEvent.start_time}`).add(1, 'hours').format('hh:mm:ss')
        };

        if (this.selectedEvent.is_new) {
          this.createEvent(data);
        } else {
          this.updateEvent(data);
        }
      }
    }
  }
}
</script>
