<template>
  <v-row class="mt-5">
    <v-col cols="12">
      <v-card outlined elevation="3">
        <v-card-text>
          <p class="title">{{ $t("userReviews") }}</p>
          <v-divider></v-divider>
          <div v-if="loading">
            <v-skeleton-loader
                v-for="index in 5"
                :key="index"
                v-bind="skeletonAttrs"
                type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
            ></v-skeleton-loader>
          </div>
          <div v-if="!loading && reviews.length !== 0">
            <div v-for="(item, x) in reviews" :key="x">
              <v-col cols="12" class="my-5">
                <v-row>
                  <v-col cols="3" md="2">
                    <v-row align="center" justify="center">
                      <v-avatar width="4rem" height="4rem">
                        <img
                            v-if="!item.jobs.client_user.profile_img"
                            src="@/assets/images/page-img/avatar.png"
                            alt="user"
                            class="avatar-70"
                        />
                        <img
                            v-else
                            :src="item.jobs.client_user.profile_img"
                            class="rounded-circle avatar-70"
                            alt="user"
                        />
                      </v-avatar>
                    </v-row>
                  </v-col>
                  <v-col cols="9" md="10">
                    <v-row>
                      <h5 class="mb-0 secondary-prosk">
                        <a href="#" class="secondary-prosk">
                          {{ item.jobs.client_user.first_name }}
                          {{ item.jobs.client_user.last_name }}
                        </a>
                      </h5>
                    </v-row>
                    <v-row>
                      <v-rating
                          readonly
                          dense
                          v-model="item.rating"
                          background-color="#59D79D"
                          color="#F2D022"
                      ></v-rating>
                      <span class="mt-1 secondary-prosk ml-2">
                        {{ getDateFormat(item.created_at) }} {{ (getDateFormat(item.created_at) > 1) ? $t('daysAgo'): $t('dayAgo') }}
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="mb-2 secondary-prosk">{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle class="secondary-prosk">{{ item.content }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-row>
              </v-col>
            </div>
          </div>
          <div v-if="!loading && reviews.length === 0">
            <v-col cols="12" class="my-3">
              <v-row>
                <v-col cols="12">
                  <alert :message="$t('userHasNotReviews')"/>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import Alert from '@/components/socialvue/alerts/Alert.vue';

export default {
  name: 'UserReviews',
  components: { Alert },
  props: ['currentUser', 'isSearchedUser'],
  mixins: [errorDialog],
  data () {
    return {
      reviews: [],
      loading: true,
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      }
    };
  },
  created () {
    this.getReviews();
  },
  methods: {
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    },
    getReviews () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?filters=jobs.prosker_user_id=${this.currentUser.id}|&sortBy=created_at,desc`;
      axios
          .get(url)
          .then((response) => {
            this.reviews = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.showError(error);
            this.loading = false;
          });
    }
  }
};
</script>
<style scoped>
  .title {
    color: #F27649;
    font-weight: bold;
  }
</style>
