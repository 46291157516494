<template>
  <v-row class="mt-5">
    <delete-warning
      :confirm-delete="deletePost"
      :on-close="onDeleteClose"
      :show-delete="showDelete"
      :loading="loadingPosts"
    />
    <delete-warning
      :confirm-delete="deleteUserCategory"
      :on-close="onDeleteUserCategoryClose"
      :show-delete="showUserCategoryDelete"
      :loading="loadingUserCategory"
    />
    <delete-warning
      :confirm-delete="deleteUserSkill"
      :on-close="onDeleteUserSkillClose"
      :show-delete="showUserSkillDelete"
      :loading="loadingUserSkill"
    />
    <delete-warning
      :confirm-delete="deleteComment"
      :on-close="onDeleteCommentClose"
      :show-delete="showCommentDelete"
      :loading="loadingUserSkill"
    />
    <v-col cols="12" md="5">
      <v-card elevation="3" class="round">
        <v-card-text>
          <p class="mb-2 title">{{ $t("contactInformation") }}</p>
          <div class="d-flex">
            <h6 class="secondary-prosk font-weight-bold">{{ $t("name") }}</h6>
            <p class="ml-5 secondary-prosk">{{ currentUser.first_name }} {{ currentUser.last_name }}</p>
          </div>
          <div class="d-flex">
            <h6 class="secondary-prosk font-weight-bold" v-show="visitorUser && visitorUser.type_user === $options.USER_TYPE_ADMIN">{{ $t("email") }}</h6>
            <p class="ml-5 secondary-prosk" v-show="visitorUser && visitorUser.type_user === $options.USER_TYPE_ADMIN">{{ currentUser.email }}</p>
          </div>
          <v-divider></v-divider>
          <p class="title">{{ $t("profile") }}</p>
          <p v-if="currentUser.description" class="ma-0 secondary-prosk">{{ currentUser.description }}</p>
          <alert v-show="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER" :message="!isSearchedUser ? $t('profileEmpty') : $t('userProfileEmpty')" v-else/>
          <div class="mt-3">
            <user-dialog
              :item="currentUser"
              v-if="!isSearchedUser"
              :on-close="onAddClose"
              :show-add="showAdd"
              :is-edit="isEdit"
              :new-item="showUserDialog"
            >
            <template v-slot:customButton="{ on, click }" >
              <v-btn
                      @click="click"
                      color="bg-prosk-secondary"
                      small
                      class="white--text mr-2 custom-btn"
                      v-on="on"
                    >
                      {{ isEdit ? $t("editUser") : $t("createUser") }}
              </v-btn>
            </template>
            </user-dialog>
            <v-btn
              :to="{ name: 'social.my-addresses' }"
              small
              outlined
              v-if="!isSearchedUser"
              class="secondary-prosk mr-2 custom-btn"
            >
              {{ $t("myAddresses") }}
            </v-btn>
          </div>
          <div v-if="!isSearchedUser && !currentUser.mp_access_token && (currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER">
            <v-divider></v-divider>
            <div class="mt-3">
              <v-btn
                @click="createMPAccount"
                small
                color="light-blue lighten-1 custom-btn"
              >
                <img width="20" class="mr-3 round" src="@/assets/images/page-img/mp.png" alt="mercado pago" />
                {{ $t("associateAccount") }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-5 round" v-show="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER" outlined elevation="3">
        <v-card-text>
          <p class="mb-2 title">
            {{ $t("categories") }}
            <add-category-to-user
              :user="currentUser"
              v-if="!isSearchedUser"
              :on-close="onAddCloseCategory"
              :show-add="showAddCategory"
              :new-item="addCategoryToUser"
            />
          </p>
          <v-row v-if="currentUser.categories.length !== 0">
            <v-chip-group column>
              <v-chip
                v-for="(item, i) in currentUser.categories"
                :key="i"
                class="ma-2"
                color="#3F44A6"
                text-color="white"
                :close="!isSearchedUser"
                :disabled="loadingUserCategory"
                @click:close="openUserCategoryWarning(item.id)"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-else>
            <alert :message="!isSearchedUser ? $t('noCategories') : $t('userNoCategories')"/>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-5 round" v-show="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER" outlined elevation="3">
        <v-card-text>
          <p class="mb-2 title">
            {{ $t("skills") }}
            <add-skills-to-user
              :user="currentUser"
              v-if="!isSearchedUser"
              :on-close="onAddCloseSkill"
              :show-add="showAddSkill"
              :new-item="addSkillToUser"
            />
          </p>
          <v-row v-if="currentUser.skills.length !== 0">
            <v-chip-group column>
              <v-chip
                v-for="(item, i) in currentUser.skills"
                :key="i"
                class="ma-2"
                color="#3F44A6"
                text-color="white"
                :close="!isSearchedUser"
                :disabled="loadingUserSkill"
                @click:close="openUserSkillsWarning(item.id)"
              >
                {{ item.skill_name }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <v-row v-else>
            <alert :message="!isSearchedUser ? $t('noSkills') : $t('userNoSkills')"/>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-5 round"
        outlined
        elevation="3"
        v-if="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER"
      >
        <v-card-text>
          <UserReviewsCore :current-user="currentUser" />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <profile-completion v-if="isCurrentUser" />
      <v-card outlined elevation="3" v-if="!isSearchedUser" class="round mt-5">
        <v-card-text>
          <p class="title">{{ $t("createPost") }}</p>
          <v-divider></v-divider>
          <v-row class="px-6">
            <v-col cols="3" md="2" class="d-none d-md-block">
              <img
                v-if="!currentUser.profile_img"
                src="@/assets/images/page-img/avatar.png"
                class="rounded-circle avatar-70"
                width="70"
                alt="user"
              />
              <img
                v-else
                :src="currentUser.profile_img"
                class="rounded-circle avatar-70"
                alt="user"
                width="70"
                height="70"
              />
            </v-col>
            <v-col cols="12" md="10">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="title"
                    outlined
                    dense
                    :placeholder="$t('titlePost')"
                    color="#59D79D"
                    class="input-text"
                  ></v-text-field>
                  <v-text-field
                    v-model="content"
                    :placeholder="$t('contentPost')"
                    outlined
                    dense
                    :hide-details="contentErrors.length === 0"
                    :error-count="contentErrors.length"
                    :error-messages="contentErrors"
                    color="#59D79D"
                    class="input-text"
                  ></v-text-field>
                  <v-btn class="button-select" elevanted color="#59D79D">
                    <v-icon>mdi-plus-circle-outline</v-icon> {{ $t('clickToAddImage') }}
                    <v-file-input
                      v-if="!url_video"
                      :label="$t('clickToAddImage')"
                      class="secondary-prosk input-file"
                      dense
                      @change="handleFileUpload"
                      hide-details
                      accept="image/*"
                      v-model="url_image"
                      prepend-icon="mdi-plus-circle-outline"
                    ></v-file-input>
                  </v-btn>
                  <v-btn class="button-select" elevanted color="#59D79D">
                    <v-icon>mdi-plus-circle-outline</v-icon> {{ $t('clickToAddVideo') }}
                    <v-file-input
                      :rules="videoRules"
                      :label="$t('clickToAddVideo')"
                      class="secondary-prosk input-file"
                      dense
                      hide-details
                      @change="handleFileUpload"
                      accept="video/*"
                      v-if="!url_image"
                      v-model="url_video"
                      prepend-icon="mdi-plus-circle-outline"
                    ></v-file-input>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    class="float-end white--text custom-btn"
                    color="bg-green-prosk"
                    @click="createPost"
                    :loading="loading"
                  >
                    {{ $t("post") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined elevation="3" class="mt-5 round secondary-prosk" v-if="!loadingPostsList && posts.length === 0">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <alert :message="$t('noPosts')"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div v-if="posts.length">
        <v-card outlined elevation="3" :class="(isSearchedUser && index > 0) || !isSearchedUser ? 'mt-5' : ''"
                v-for="(item, index) in posts" :key="index" class="round secondary-prosk mb-2">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-content-between">
                <div>
                  <div class="d-flex align-items-center">
                    <img
                      v-if="!currentUser.profile_img"
                      src="@/assets/images/page-img/avatar.png"
                      class="avatar-60 me-3 rounded-circle img-fluid"
                      width="70"
                      alt="user"
                    />
                    <img
                      v-else
                      :src="currentUser.profile_img"
                      class="avatar-60 me-3 rounded-circle img-fluid"
                      alt="user"
                      width="70"
                      height="70"
                    />
                    <div class="text-left">
                      <p class="ma-0 font-weight-bold secondary-prosk">{{ currentUser.username }}</p>
                      <span class="secondary-prosk">{{ getPostHour(item.created_at) }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="!isSearchedUser">
                  <v-menu transition="slide-y-transition" left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" class="custom-btn">
                        <v-icon class="secondary-prosk">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item dense ripple @click="openWarning(item.id)">
                        <v-btn icon x-small class="float-left custom-btn">
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                        {{ $t("delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="px-6 text-left">
              <v-col cols="12" class="pb-0">
                <h5 class="mb-2 secondary-prosk" v-if="item.title">{{ item.title }}</h5>
              </v-col>
            </v-row>
            <v-row class="px-6 text-left">
              <v-col cols="12" class="py-0">
                <p class="mt-0 mb-2 secondary-prosk" v-if="item.content">{{ item.content }}</p>
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col cols="12">
                <img
                  v-if="item.url_image"
                  :src="item.url_image"
                  alt="post-image"
                  class="img-fluid w-100 round"
                />
              </v-col>
            </v-row>
            <v-row class="px-6">
              <v-col cols="12">
                <video
                  class="w-100"
                  id="video-preview"
                  controls
                  v-if="item.url_video"
                >
                  <source :src="item.url_video" />
                </video>
              </v-col>
            </v-row>
            <v-row class="margin-y">
              <v-col cols="3">
                <v-btn v-if="isSearchedUser" class="custom-btn" icon @click="toggleLike(item, item.has_like ? 0 : 1)">
                  <v-icon color="#59D79D" class="mr-1">
                    {{ item.has_like ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                  </v-icon>
                </v-btn>
                <span v-if="item.count_likes > 0" class="secondary-prosk">{{ item.count_likes }} {{ $t('like') }}</span>
                <span v-if="item.count_likes === 0 && isSearchedUser" class="secondary-prosk">{{ $t('like') }}</span>
              </v-col>
              <v-col class="secondary-prosk cursor-pointer">
                <span
                  v-show="isSearchedUser && visitorUser"
                >{{ `${(item.comments && item.comments.length) || 0} ${$t('comments')}` }}</span>
                <button @click="toggleShowComments(item)" class="ml-5 secondary-prosk cursor-pointer font-weight-bold"
                  v-show="item.comments && item.comments.length > 2"
                >
                  {{ $t('showAll') }}
                </button>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="relative">
            <input
              class="input-comment"
              v-show="isSearchedUser"
              :placeholder="$t('commentsPlaceholder')"
              v-model="item.comment"
              @keyup.enter="addComment(item)"
            />
            <button @click="addComment(item)">
              <v-icon class="secondary-prosk absolute" v-show="isSearchedUser">mdi-send</v-icon>
            </button>
            <span class="invalid-tooltip">{{ forceRender }}</span>
          </v-card-text>

          <div class="comment" v-for="(comment, index) in item.comments" :key="index" v-show="(index >= item.comments.length - 2) || item.showComments">
            <div class="ml-10" v-if="item.comments && item.comments.length">
              <div class="input-enter">
                <p class="comment-name"> {{ item.comments && item.comments[item.comments.length - index - 1].user.first_name }} {{ item.comments && item.comments[item.comments.length - index - 1].user.last_name }} </p>
                <span
                  class="comment-text"
                  v-show="!(commentEditing.post === item.id && commentEditing.comment === item.comments[item.comments.length - index - 1].id)"
                >{{ item.comments && item.comments[item.comments.length - index - 1].comment }}</span>
                <input
                  v-show="commentEditing.post === item.id && commentEditing.comment === item.comments[item.comments.length - index - 1].id"
                  class="input-comment"
                  v-model="item.comments[0].comment"
                  @keyup.enter="updateComment(item, item.comments[item.comments.length - index - 1].id, 0)"
                />
              </div>
            </div>
            <div v-if="item.comments && item.comments.length && visitorUser && visitorUser.id === item.comments[item.comments.length - index - 1].user.id">
              <v-menu transition="slide-y-transition" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="custom-btn">
                    <v-icon class="secondary-prosk">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item dense ripple @click="onCommentEdit(item.id, item.comments[item.comments.length - index - 1].id)">
                    <v-btn icon x-small class="float-left custom-btn">
                      <v-icon class="mr-2"> mdi-pencil </v-icon>
                    </v-btn>
                    {{ $t("edit") }}
                  </v-list-item>
                  <v-list-item dense ripple @click="openCommentWarning(item.id, item.comments[item.comments.length - index - 1].id)">
                    <v-btn icon x-small class="float-left custom-btn">
                      <v-icon class="mr-2"> mdi-delete </v-icon>
                    </v-btn>
                    {{ $t("delete") }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

        </v-card>
        <v-main class="text-center mt-5" v-if="!hideMorePostsBtn">
          <v-btn
              x-small
              plain
              link
              color="teal darken-4"
              :disabled="loadingPostsList"
              @click="getPosts(true)"
              class="custom-btn"
            >
              <v-icon class="secondary-prosk">mdi-cached</v-icon>
              <span class="ml-2 secondary-prosk">{{ $t("loadOldPosts") }}</span>
          </v-btn>
        </v-main>
      </div>
      <v-card outlined elevation="3" :class="!isSearchedUser ? 'mt-5' : ''"  v-if="loadingPostsList" class="round">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
              v-for="index in 3"
              :key="index"
              v-bind="skeletonAttrs"
              type="list-item-avatar, divider, list-item-three-line, card-heading, actions">
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapActions } from 'vuex';
import UserDialog from '@/components/socialvue/dialogs/UserDialog';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import AddCategoryToUser from '@/components/socialvue/dialogs/AddCategoryToUser';
import AddSkillsToUser from '@/components/socialvue/dialogs/AddSkillsToUser';
import UserReviewsCore from '@/views/User/UserReviewsCore';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_PROSKER, USER_TYPE_ADMIN } from '@/misc/constants';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
import ProfileCompletion from '@/views/User/ProfileCompletion';
export default {
  name: 'UserDetails',
  props: ['currentUser', 'isSearchedUser', 'visitorUser'],
  components: {
    Alert,
    AddSkillsToUser,
    AddCategoryToUser,
    UserDialog,
    DeleteWarning,
    UserReviewsCore,
    ProfileCompletion
  },
  mixins: [errorDialog, verifyImageSize],
  data () {
    return {
      contentErrors: [],
      isEdit: true,
      loadingUserCategory: false,
      loadingUserSkill: false,
      loadingPosts: false,
      loadingComments: false,
      title: '',
      url_image: null,
      content: null,
      url_video: null,
      url: null,
      videoRules: [
        v => !v || this.verifyVideoSize(v)
      ],
      loading: false,
      showDialogUserBanner: false,
      showAdd: false,
      showAddCategory: false,
      showBanDialog: false,
      showAddSkill: false,
      dialogBannerPhoto: false,
      dialogUserPhoto: false,
      userIds: [],
      userData: {},
      name: '',
      posts: [],
      hideMorePostsBtn: false,
      page: 1,
      showDelete: false,
      showAddImage: false,
      postIdToDelete: '',
      commentIdToDelete: '',
      idCategory: [],
      idSkill: [],
      forceRender: false,
      commentEditing: {
        post: 0,
        comment: 0
      },
      showUserCategoryDelete: false,
      showUserSkillDelete: false,
      showCommentDelete: false,
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loadingPostsList: true
    };
  },
  watch: {
    getMissingData: function (value) {
      if (value) {
        this.fillMissingData();
      }
    }
  },
  created () {
    this.setImportedConstants();
    this.getPosts();
  },
  computed: {
    userLoggedIn () {
      return this.$store.getters['auth/currentUser'];
    },
    isCurrentUser () {
      return this.userLoggedIn && this.currentUser && this.userLoggedIn.id === this.currentUser.id;
    }
  },
  methods: {
    toggleLike (item, addLike) {
      if (!this.userLoggedIn) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/likes-post/${item.id}`;
        const data = {
          like: addLike
        };
        axios.post(url, data).then(() => {
          item.has_like = addLike;
          addLike ? item.count_likes++ : item.count_likes--;
        }).catch((error) => {
          this.showError(error);
        });
      }
    },
    toggleShowComments (item) {
      item.showComments = !item.showComments;
      this.forceRender = !this.forceRender;
    },
    addComment (item) {
      if (!this.userLoggedIn) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
        return;
      }

      const url = `${process.env.VUE_APP_BASE_URL}/api/post/${item.id}/comments`;
      const data = {
        comment: item.comment
      };

      axios.post(url, data).then((response) => {
        item.comments = item.comments || [];
        item.comments.push(response.data.data[0]);
        item.comment = '';
        this.forceRender = !this.forceRender;
      }).catch((error) => {
        this.showError(error);
      });
    },
    onCommentEdit (item, comment) {
      this.commentEditing = {
        post: item,
        comment: comment
      }
    },
    updateComment (item, apiIndex, index) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/post/${item.id}/comments/${apiIndex}`;
      const data = {
        comment: item.comments[index].comment
      };

      axios.put(url, data).then(() => {
        this.commentEditing = {
          post: 0,
          comment: 0
        }
      }).catch((error) => {
        this.showError(error);
      });
    },
    deleteComment () {
      this.loadingComments = true;

      const url = `${process.env.VUE_APP_BASE_URL}/api/comments/${this.commentIdToDelete}`;
      axios.delete(url).then(() => {
        this.posts = this.posts.map((post) => {
          post.comments = post.comments.filter((comment) => {
            return comment.id !== this.commentIdToDelete;
          });
          return post;
        });
      }).catch((error) => {
        this.showError(error);
      }).finally(() => {
        this.loadingComments = false;
        this.onDeleteCommentClose();
      })
    },
    setImportedConstants () {
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.USER_TYPE_ADMIN = USER_TYPE_ADMIN;
    },
    createMPAccount () {
      window.location.href = `${process.env.VUE_APP_MP_AUTH_URL}`;
    },
    getUserAddress (currentUser) {
      let defaultAddress = '';
      currentUser.addresses.forEach((address) => {
        if (!address.is_default) {
          defaultAddress = address.city + ',' + address.country;
        }
      });
      return defaultAddress;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    onDeleteUserCategoryClose () {
      this.idCategory = [];
      this.showUserCategoryDelete = false;
    },
    onDeleteUserSkillClose () {
      this.idSkill = [];
      this.showUserSkillDelete = false;
    },
    onDeleteCommentClose () {
      this.showCommentDelete = false;
    },
    openWarning (item) {
      this.showDelete = true;
      this.postIdToDelete = item;
    },
    openUserCategoryWarning (item) {
      this.showUserCategoryDelete = true;
      this.idCategory.push(item);
    },
    openUserSkillsWarning (item) {
      this.showUserSkillDelete = true;
      this.idSkill.push(item);
    },
    openCommentWarning (item, comment) {
      this.showCommentDelete = true;
      this.postIdToDelete = item;
      this.commentIdToDelete = comment;
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    deleteUserCategory () {
      this.loadingUserCategory = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/categories/delete`;
      axios
        .delete(url, {
          data: {
            user_id: this.currentUser.id,
            category_ids: this.idCategory
          }
        })
        .then(() => {
          this.updateUser(this.currentUser.id);
          this.updatePercentage(this.currentUser.id);
          this.loadingUserCategory = false;
          this.idCategory = [];
        })
        .catch((error) => {
          this.loadingUserCategory = false;
          this.idCategory = [];
          this.showError(error);
        });
      this.onDeleteUserCategoryClose();
    },
    deleteUserSkill () {
      this.loadingUserSkill = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/skills/delete`;
      axios
        .delete(url, {
          data: {
            user_id: this.currentUser.id,
            skill_ids: this.idSkill
          }
        })
        .then(() => {
          this.updateUser(this.currentUser.id);
          this.updatePercentage(this.currentUser.id);
          this.loadingUserSkill = false;
          this.idSkill = [];
        })
        .catch((error) => {
          this.loadingUserSkill = false;
          this.idSkill = [];
          this.showError(error);
        });
      this.onDeleteUserSkillClose();
    },
    deletePost () {
      this.loadingPosts = true;
      const url =
        process.env.VUE_APP_BASE_URL + '/api/posts/' + this.postIdToDelete;
      axios
        .delete(url)
        .then(() => {
          this.updateUser(this.currentUser.id);
          this.updatePercentage(this.currentUser.id);
          this.posts = this.posts.filter(item => item.id !== this.postIdToDelete);
          this.loadingPosts = false;
        })
        .catch((error) => {
          this.loadingPosts = false;
          this.showError(error);
        });
      this.onDeleteClose();
    },
    verifyVideoSize (event) {
      this.file = event;

      if (this.file.size > 1000000) {
        this.$swal.fire({
          title: this.$t('ohNo'),
          text: this.$t('videoFileSizeToBig'),
          confirmButtonText: this.$t('accept')
        });

        this.url_video = null;
      }
    },
    getPostHour (hour) {
      const originalDate = new Date(hour);
      return moment(moment.utc(originalDate)).format('DD/MM/YYYY HH:mm');
    },
    getPosts (more) {
      if (!this.currentUser) {
        return;
      }

      if (more) {
        this.page++;
      }
      this.loadingPostsList = true;
      const url = process.env.VUE_APP_BASE_URL + `/api/posts?filters=user_id=${this.currentUser.id}&page=${this.page}&sortBy=id,desc`;
      axios.get(url).then((response) => {
        this.posts = this.posts.concat(response.data.data);
        if (this.userLoggedIn) {
          this.posts = this.posts.map(post => {
            if (this.userLoggedIn) {
              post.has_like = !!post.liked_by_users.find(i => i === this.userLoggedIn.id);
            }

            return post;
          });
        }
        this.loadingPostsList = false;
        this.hideMorePostsBtn = (this.page === response.data.last_page);
      }).catch((error) => {
          this.showError(error);
          this.loadingPostsList = false;
        });
    },
    resetPostForm () {
      this.title = '';
      this.content = null;
      this.url_image = null;
      this.url_video = null;
    },
    getFormData () {
      const formData = new FormData();
      if (this.url_image != null) {
        formData.append('url_image', this.url_image);
      }
      if (this.url_video != null) {
        formData.append('url_video', this.url_video);
      }
      formData.set('title', this.title);
      formData.set('content', this.content);
      return formData;
    },
    onAddCallback () {
      this.resetUserImage();
    },
    createPost () {
      this.contentErrors = [];
      if (!this.content) {
        this.contentErrors.push(this.$t('contentRequired'));
      } else {
        this.loading = true;
        const formData = this.getFormData();
        const url = process.env.VUE_APP_BASE_URL + '/api/posts';
        axios
          .post(url, formData)
          .then((response) => {
            this.resetPostForm();
            this.updatePercentage(this.currentUser.id);
            this.posts = [response.data.data].concat(this.posts);
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error, false, false, false, this.$t('imageSizeWarning'));
          });
      }
    },
    newProfileImage () {
      this.showAddImage = true;
    },
    newBanner () {
      this.showDialogUserBanner = true;
    },
    onAddClose () {
      this.showAdd = false;
    },
    showUserDialog () {
      this.showAdd = true;
    },
    onAddImageClose () {
      this.showAddImage = false;
    },
    onBannerDialogClose () {
      this.showDialogUserBanner = false;
    },
    onAddCloseCategory () {
      this.showAddCategory = false;
    },
    onAddCloseSkill () {
      this.showAddSkill = false;
    },
    addCategoryToUser () {
      this.showAddCategory = true;
    },
    banUser () {
      this.showBanDialog = true;
    },
    addSkillToUser () {
      this.showAddSkill = true;
    }
  }
};
</script>
<style scoped>
  .round {
    border-radius: 12px;
  }

  .title {
    color: #F27649;
    font-weight: bold;
  }

  .custom-btn {
    border-radius: 100px;
  }

  .input-text {
    border-radius: 100px;
    color: #3F44A6;
    --placeholder-color: #3F44A6;
  }

  .button-select {
    position: relative;
    height: 40px;
    width: 100px;
    border-radius: 100px;
    margin-top: 20px;
    background: #59D79D;
    color: #3F44A6;
    cursor: pointer;
    margin-left: 12px;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
    top: 20px;
    right: 32px;
  }

  .margin-y {
    margin-top: -28px;
    margin-bottom: -32px;
  }

  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 10;
  }

  .input-comment {
    border-radius: 100px;
    color: #3F44A6;
    --placeholder-color: #3F44A6;
    border: 1px solid #59D79D;
    padding: 4px 12px;
    width: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .comment {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .input-enter {
    width: 260%;
  }

  .comment-name {
    font-weight: bold;
    color: #3F44A6;
    font-size: .9rem;
    margin-bottom: 0;
  }

  .comment-text {
    font-size: .8rem;
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    .input-enter {
      width: 100%;
    }
  }

</style>
