<template>
  <v-row class="mt-5">
    <v-col cols="12" md="5">
      <v-card elevation="2">
        <v-card-text>
          <v-list v-if="preferences.length">
            <v-list-item-group v-model="selectedPreference" color="#59D79D">
              <v-list-item
                  v-for="(preference, i) in preferences"
                  :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ $t(preference.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <div v-if="loading">
        <v-skeleton-loader
            v-for="index in 3"
            :key="index"
            v-bind="skeletonAttrs"
            type="divider, list-item-three-line, card-heading"
        ></v-skeleton-loader>
      </div>
      <v-card outlined elevation="3" v-if="!loading">
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content></v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col class="mx-3">
                    <v-icon color="#59D79D"> mdi-email</v-icon>
                  </v-col>
                  <v-col class="mx-1">
                    <v-icon color="#59D79D"> mdi-whatsapp</v-icon>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
                v-for="(preference, i) in notificationPreferences"
                :key="i"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ $t(preference.notification_name) }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t(preference.description) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col>
                    <v-switch
                        @change="
                              updatePreference(
                                preference.id,
                                preferenceSelectedEmail[
                                  preference.notification_name
                                ],
                                preferenceSelectedWhatsApp[
                                  preference.notification_name
                                ]
                              )
                            "
                        v-model="
                              preferenceSelectedEmail[
                                preference.notification_name
                              ]
                            "
                        color="#59D79D"
                        :disabled="!preference.is_editable"
                    ></v-switch>
                  </v-col>
                  <v-col>
                    <v-switch
                        @change="
                              updatePreference(
                                preference.id,
                                preferenceSelectedEmail[
                                  preference.notification_name
                                ],
                                preferenceSelectedWhatsApp[
                                  preference.notification_name
                                ]
                              )
                            "
                        v-model="
                              preferenceSelectedWhatsApp[
                                preference.notification_name
                              ]
                            "
                        color="#59D79D"
                        :disabled="!preference.is_editable"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';

import errorDialog from '@/components/socialvue/mixins/errorDialog';

export default {
  name: 'UserReviews',
  mixins: [errorDialog],
  data () {
    return {
      preferenceSelectedEmail: {},
      preferenceSelectedWhatsApp: {},
      preferences: [
        {
          icon: 'mdi-send',
          text: 'notifications'
        }
      ],
      selectedPreference: 0,
      loading: false,
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      }
    };
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  mounted () {
    this.getNotificationPreferences();
  },
  methods: {
    getNotificationPreferences () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      this.notificationPreferences = [];
      const url = `${process.env.VUE_APP_BASE_URL}/api/notification-preferences?filters=user_id=${this.currentUser.id}|`;
      axios
          .get(url)
          .then((response) => {
            response.data.data.forEach((preference) => {
              this.preferenceSelectedWhatsApp[preference.notification_name] =
                  preference.whatsapp_enabled;
              this.preferenceSelectedEmail[preference.notification_name] =
                  preference.email_enabled;
            });
            this.notificationPreferences = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
    },
    updatePreference (id, emailValue, whatsAppValue) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/notification-preferences/${id}`;
      const data = {
        email_enabled: emailValue,
        whatsapp_enabled: whatsAppValue
      };
      axios
          .patch(url, data)
          .then(() => {
          })
          .catch((error) => {
            this.showError(error);
          });
    }
  }
};
</script>
