var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[(_vm.preferences.length)?_c('v-list',[_c('v-list-item-group',{attrs:{"color":"#59D79D"},model:{value:(_vm.selectedPreference),callback:function ($$v) {_vm.selectedPreference=$$v},expression:"selectedPreference"}},_vm._l((_vm.preferences),function(preference,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(preference.text)))])],1)],1)}),1)],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[(_vm.loading)?_c('div',_vm._l((3),function(index){return _c('v-skeleton-loader',_vm._b({key:index,attrs:{"type":"divider, list-item-three-line, card-heading"}},'v-skeleton-loader',_vm.skeletonAttrs,false))}),1):_vm._e(),(!_vm.loading)?_c('v-card',{attrs:{"outlined":"","elevation":"3"}},[_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content'),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"mx-3"},[_c('v-icon',{attrs:{"color":"#59D79D"}},[_vm._v(" mdi-email")])],1),_c('v-col',{staticClass:"mx-1"},[_c('v-icon',{attrs:{"color":"#59D79D"}},[_vm._v(" mdi-whatsapp")])],1)],1)],1)],1),_vm._l((_vm.notificationPreferences),function(preference,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(preference.notification_name))+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t(preference.description))+" ")])],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"color":"#59D79D","disabled":!preference.is_editable},on:{"change":function($event){return _vm.updatePreference(
                              preference.id,
                              _vm.preferenceSelectedEmail[
                                preference.notification_name
                              ],
                              _vm.preferenceSelectedWhatsApp[
                                preference.notification_name
                              ]
                            )}},model:{value:(
                            _vm.preferenceSelectedEmail[
                              preference.notification_name
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.preferenceSelectedEmail, 
                              preference.notification_name
                            , $$v)},expression:"\n                            preferenceSelectedEmail[\n                              preference.notification_name\n                            ]\n                          "}})],1),_c('v-col',[_c('v-switch',{attrs:{"color":"#59D79D","disabled":!preference.is_editable},on:{"change":function($event){return _vm.updatePreference(
                              preference.id,
                              _vm.preferenceSelectedEmail[
                                preference.notification_name
                              ],
                              _vm.preferenceSelectedWhatsApp[
                                preference.notification_name
                              ]
                            )}},model:{value:(
                            _vm.preferenceSelectedWhatsApp[
                              preference.notification_name
                            ]
                          ),callback:function ($$v) {_vm.$set(_vm.preferenceSelectedWhatsApp, 
                              preference.notification_name
                            , $$v)},expression:"\n                            preferenceSelectedWhatsApp[\n                              preference.notification_name\n                            ]\n                          "}})],1)],1)],1)],1)})],2)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }