<template>
  <div>
    <p class="title">{{ $t("userReviews") }}</p>
    <v-divider></v-divider>
    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 5"
        :key="index"
        v-bind="skeletonAttrs"
        type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loading && reviews.length !== 0">
      <div v-for="(item, x) in reviews" :key="x">
        <v-col cols="12" class="my-5">
          <v-row>
            <v-col cols="3" md="2">
              <v-avatar width="4rem" height="4rem">
                <img
                  v-if="!item.jobs.client_user.profile_img"
                  src="@/assets/images/page-img/avatar.png"
                  alt="user"
                  class="avatar-70"
                />
                <img
                  v-else
                  :src="item.jobs.client_user.profile_img"
                  class="rounded-circle avatar-70"
                  alt="user"
                />
              </v-avatar>
            </v-col>
            <v-col cols="9" md="10">
              <p class="ma-0 font-weight-bold secondary-prosk">
                {{ item.jobs.client_user.first_name }}
                {{ item.jobs.client_user.last_name }}
              </p>
              <div class="d-flex">
                <v-rating
                  readonly
                  dense
                  v-model="item.rating"
                  background-color="#59D79D"
                  color="#F2D022"
                  size="18"
                ></v-rating>
                <p class="mt-1 ml-2 text-caption secondary-prosk">
                  {{ getDateFormat(item.created_at) }}
                  {{
                    getDateFormat(item.created_at) > 1
                      ? $t("daysAgo")
                      : $t("dayAgo")
                  }}
              </p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h5 class="mb-2 secondary-prosk">{{ item.title }}</h5>
              <p class="ma-0 secondary-prosk">
                {{ item.content }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="text-center" v-if="lastPage !== 1">
        <v-pagination
          v-model="reviewsPage"
          color="#59D79D"
          :length="lastPage"
          circle
        ></v-pagination>
      </div>
    </div>
    <div v-if="!loading && reviews.length === 0">
      <alert :message="$t('userHasNotReviews')"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Alert from '@/components/socialvue/alerts/Alert.vue';

export default {
  name: 'UserReviewsCore',
  components: { Alert },
  props: ['currentUser'],
  data () {
    return {
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 3
      },
      reviewsPage: 1,
      lastPage: 1,
      reviews: [],
      loading: true
    };
  },
  watch: {
    reviewsPage: function (value) {
      if (value) {
        this.getReviews();
      }
    }
  },
  created () {
    this.getReviews();
  },
  methods: {
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    },
    getReviews () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?filters=jobs.prosker_user_id=${this.currentUser.id}|&page=${this.reviewsPage}&per_page=5&sortBy=created_at,desc`;
      axios
        .get(url)
        .then((response) => {
          this.reviews = response.data.data;
          this.lastPage = response.data.last_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    }
  }
};
</script>
<style scoped>
.title {
  color: #F27649;
  font-weight: bold;
}
</style>
