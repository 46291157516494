<template>
  <v-dialog
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="saveUserImage"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" color="bg-green-prosk" dark v-on="on">
        <v-icon small>mdi-camera</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline">{{ $t("pickUserImage") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-file-input
              :label="$t('clickToAddImage')"
              class="image-input"
              accept="image/*"
              @change="handleFileUpload"
              prepend-icon="mdi-camera"
              v-model="profile_img"
              color="#59D79D"
              :error-count="errors.length"
              :error-messages="errors"
            ></v-file-input>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="saveUserImage" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
export default {
  name: 'UserProfileImageDialog',
  components: { CancelButton, SaveButton },
  props: ['showAdd', 'onClose', 'newItem'],
  mixins: [errorDialog, verifyImageSize],
  data () {
    return {
      profile_img: null,
      loading: false,
      errors: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onNewClicked () {
      this.resetErrors();
      this.resetUserImage();
      this.newItem();
    },
    resetErrors () {
      this.errors = [];
    },
    getFormData () {
      const formData = new FormData();
      if (this.profile_img != null) {
        formData.append('profile_img', this.profile_img);
      }
      return formData;
    },
    resetUserImage () {
      this.profile_img = null;
    },
    saveUserImage () {
      this.resetErrors();
      this.loading = true;
      const formData = this.getFormData();
      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/users/${this.currentUser.id}/medias`;
      if (this.profile_img == null) {
        this.errors.push(this.$t('photoFieldRequired'));
        this.loading = false;
      } else {
        axios
          .post(url, formData)
          .then(() => {
            this.updateUser(this.currentUser.id);
            this.updatePercentage(this.currentUser.id);
            this.resetUserImage();
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
