<template>
  <v-card elevation="2" class="round" v-if="missingData.length !== 0">
        <v-card class="">
          <v-alert dense class="fill-height p-4">
            <p style="color:#F27649;" class="title font-weight-bold title mb-4 text-center">{{ $t("completeYourProfile") }}</p>
            <h4 class="title secondary-prosk text-center">{{ $t('missingInformation') }}</h4>
            <div class="p-2 secondary-prosk d-flex row justify-content-around align-items-center flex-row-reverse flex-wrap mt-2">
              <div
                align-top
                dense
                p-3
              >
                <div
                  v-for="(item, i) in missingData"
                  :key="i"
                  :color="item.color"
                  small
                >
                  <div class="d-flex porcentage-list">
                    <div :class="['d-flex', item.description ? 'align-items-end' : 'align-items-center']">
                      <v-switch v-model="item.complete" color="#59D79D" :disabled="item.missing" readonly></v-switch>
                      <div>
                        <span :class="[item.color !== 'grey' ? 'secondary-prosk' : 'grey--text font-weight-bold', 'item-porcentage']">{{ item.text }}</span><br>
                        <span :class="[item.color !== 'grey' ? 'secondary-prosk' : 'grey--text font-weight-bold', 'item-porcentage-description']">{{ item.description }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                  <v-progress-circular
                    :size="200"
                    :width="20"
                    :value="getPercentage"
                    color="#59D79D"
                  >
                    <span class="font-size-34 font-weight-bold secondary-prosk"
                      >{{ getPercentage }} %
                    </span>
                </v-progress-circular>
               </div>
            </v-alert>
        </v-card>
  </v-card>
</template>

<script>
import {
  PROFILE_TAB,
  REVIEWS_TAB,
  PREFERENCES_TAB,
  CALENDAR_TAB,
  USER_TYPE_PROSKER,
  FEATURE_JOBS
} from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';

export default {
  name: 'ProfileCompletion',
  data () {
    return {
      missingData: [],
      imagePath: require('@/assets/images/page-img/green-bgr.png')
    };
  },
  watch: {
    getMissingData: function (value) {
      if (value) {
        this.fillMissingData();
      }
    }
  },
  computed: {
    getMissingData () {
      return this.$store.getters['auth/missingData'];
    },
    getPercentage () {
      return this.$store.getters['auth/percentage'];
    },
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    this.fillMissingData();
  },
  methods: {
    setImportedConstants () {
      this.$options.PROFILE_TAB = PROFILE_TAB;
      this.$options.REVIEWS_TAB = REVIEWS_TAB;
      this.$options.PREFERENCES_TAB = PREFERENCES_TAB;
      this.$options.CALENDAR_TAB = CALENDAR_TAB;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.JOBS_ENABLED = isFeatureEnabled(
        process.env.VUE_APP_RELEASE_VERSION,
        FEATURE_JOBS
      );
    },
    fillMissingData () {
      this.missingData = [
          {
            text: this.$t('addFullNameToUser'),
            description: this.$t('requireItem'),
            color: this.getMissingData.includes(8) ? 'grey' : 'green lighten-3',
            missing: this.getMissingData.includes(8),
            complete: !this.getMissingData.includes(8)
          },
         {
           text: this.$t('pickUserImage'),
           description: this.$t('requireItem'),
           color: this.getMissingData.includes(1) ? 'grey' : 'green lighten-3',
           missing: this.getMissingData.includes(1),
           complete: !this.getMissingData.includes(1)
         },
        {
          text: this.$t('addCategory'),
          description: this.$t('requireItem'),
          color: this.getMissingData.includes(2) ? 'grey' : 'green lighten-3',
          missing: this.getMissingData.includes(2),
          complete: !this.getMissingData.includes(2)
        },
        {
          text: this.$t('pickBannerImage'),
          color: this.getMissingData.includes(4) ? 'grey' : 'green lighten-3',
          missing: this.getMissingData.includes(4),
          complete: !this.getMissingData.includes(4)
        },
        {
          text: this.$t('associateAccount'),
          description: this.$t('associateAccountDescription'),
          color: this.getMissingData.includes(7) ? 'grey' : 'green lighten-3',
          missing: this.getMissingData.includes(7),
          complete: !this.getMissingData.includes(7)
        },
        {
           text: this.$t('AddSkillToUser'),
           color: this.getMissingData.includes(3) ? 'grey' : 'green lighten-3',
           missing: this.getMissingData.includes(3),
           complete: !this.getMissingData.includes(3)
        },
        {
          text: this.$t('createAddress'),
          color: this.getMissingData.includes(5) ? 'grey' : 'green lighten-3',
          missing: this.getMissingData.includes(5),
          complete: !this.getMissingData.includes(5)
        }
        // {
        //   text: this.$t('createPost'),
        //   color: this.getMissingData.includes(6) ? 'grey' : 'green lighten-3',
        //   missing: this.getMissingData.includes(6),
        //   complete: !this.getMissingData.includes(6)
        // },
      ];
      this.showMPButton = !this.missingData[6].missing;
    }
  }
};
</script>
<style scoped>
  .porcentage-list {
    height:50px;
  }
  .item-porcentage {
    font-size:16px;
    font-weight:700;
  }
  .item-porcentage-description {
    font-size:12px;
  }
</style>
